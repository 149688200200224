import React, { useState, useEffect } from "react";

function convertDateFormat(dateString) {
  const inputDate = new Date(dateString);

  // Format the date components
  const month = inputDate.toLocaleString("default", { month: "long" });
  const day = inputDate.getDate();
  const year = inputDate.getFullYear();

  // Create the formatted date string
  const formattedDate = `${month} ${day}, ${year}`;

  return formattedDate;
}

// function updateQueryStringParameter(uri, key, value) {
//   var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
//   var separator = uri.indexOf("?") !== -1 ? "&" : "?";
//   if (uri.match(re)) {
//     return uri.replace(re, "$1" + key + "=" + value + "$2");
//   } else {
//     return uri + separator + key + "=" + value;
//   }
// }

// function cleanString(inputString) {
//   // Remove special characters using regex
//   const cleanedString = inputString.replace(/[^\w\s]/gi, "");
//   // Replace spaces with hyphens
//   const finalString = cleanedString.replace(/\s+/g, "-");
//   return finalString.toLowerCase();
// }

// const cleanString = (title) => {
//   return title
//     .trim()
//     .replace(/[`~!@#$%^&*()_|+\=?;:'",.<>\{\}\[\]\\\/]/gi, "")
//     .replace(/\s+/gi, "-")
//     .replace("-–-", "-")
//     .toLowerCase();
// };

export function cleanString(inputString) {
  let specialString = inputString.replace(/[^\w\s-]/gi, "");
  specialString = specialString.replace(/\s+/g, "-");
  return specialString.toLowerCase();
}

function isOdd(number) {
  return number % 2 !== 0;
}

export const OnlyRecord = ({ data, name }) => {
  return (
    <section className="py-1  text-left container max-w-[1200px] mx-auto px-4 py-4">
      <h1 className="py-10 text-[#2E3A5E] font-bold text-3xl md:text-5xl">
        {name}
      </h1>
      {data.map(({ title, productVersion }) => {
        return (
          <p className="text-sm md:text-[15px] font-semibold py-2 flex items-center">
            <div>
              {" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                className="w-[18px] h-[18px] text-[#2E3A5E]"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                />
              </svg>
            </div>
            <div className="px-4">
              <a
                className="text-[#11B98F] hover:text-[#2E3A5E] pr-2"
                href={`https://puregpl.com/downloads/${cleanString(title)}`}
              >
                {title}
              </a>
              |<span className="pl-4 text-[#f59e0b]">{productVersion}</span>
            </div>
          </p>
        );
      })}
    </section>
  );
};

export const Table = ({ data }) => {
  return (
    <section className="py-1 bg-blueGray-50">
      <div className="w-full xl:w-8/12 mb-4 px-4 mx-auto mt-4">
        <div className="relative flex flex-col min-w-0 break-words bg-white w-full shadow-lg rounded">
          <div className="block w-full overflow-x-auto text-sm">
            <table className="items-center bg-transparent w-full border-collapse ">
              <thead className="bg-[#11B98F]">
                <tr>
                  <th className="px-6 text-gray-50 align-middle border border-solid border-blueGray-100 py-3 uppercase border-l-0 border-r-0 font-bold text-left">
                    Product Name
                  </th>
                  <th className="px-6 text-gray-50 align-middle border border-solid border-blueGray-100 py-3 uppercase border-l-0 border-r-0 font-bold md:whitespace-nowrap">
                    Product Version
                  </th>
                  <th className="px-6 text-gray-50 align-middle border border-solid border-blueGray-100 py-3 uppercase border-l-0 border-r-0 font-bold md:whitespace-nowrap">
                    Last Updated
                  </th>
                </tr>
              </thead>
              <tbody>
                {data.length
                  ? data.map(({ title, productVersion, updatedAt }, index) => {
                      return (
                        <tr
                          key={index}
                          className={`${isOdd(index) ? "bg-[#f4f4f5]" : ""}`}
                        >
                          <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-left py-2.5">
                            <a
                              className="text-[#11B98F] hover:text-[#2E3A5E]"
                              href={`https://puregpl.com/downloads/${cleanString(
                                title
                              )}`}
                            >
                              {title}
                            </a>
                          </th>
                          <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 whitespace-nowrap py-2">
                            {productVersion}
                          </td>
                          <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 md:whitespace-nowrap py-2">
                            {convertDateFormat(updatedAt)}
                          </td>
                        </tr>
                      );
                    })
                  : "No record found!!"}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>
  );
};

export const Search = ({ search }) => {
  const [value, setValue] = useState("");

  useEffect(() => {
    setValue(search);
  }, [search]);

  const setSearchHandler = () => {
    window.location.replace(`/?search=${value}`);
  };
  return (
    <div className="w-full xl:w-8/12 px-4 mt-10 mx-auto">
      <label className="flex items-center justify-center p-1 rounded shadow-2xl focus-within:border-gray-300">
        <input
          id="search-bar"
          placeholder="Search products"
          className="px-6 py-2 w-full rounded-md flex-1 outline-none bg-white font-bold text-[#2E3A5E]"
          onChange={(e) => setValue(e.target.value)}
          value={value}
        />
        <button
          onClick={setSearchHandler}
          type="submit"
          className="w-36 py-3 bg-[#11B98F] hover:bg-[#2E3A5E] text-white active:scale-95 duration-100 border will-change-transform overflow-hidden relative rounded transition-all"
        >
          <div className="flex items-center transition-all opacity-1">
            <span className="text-sm font-semibold whitespace-nowrap truncate mx-auto">
              Search
            </span>
          </div>
        </button>
      </label>
    </div>
  );
};

export const Pagination = ({ totalPage, offset }) => {
  const search = window.location.search;
  const setNext = () => {
    if (totalPage.length > offset) {
      window.location.href = `/page/${offset + 1}/${search}`;
    }
  };

  const setPrevious = () => {
    if (offset > 1) {
      window.location.href = `/page/${offset - 1}${search}`;
    }
  };

  const onPageClick = (page) => {
    window.location.href = `/page/${page}${search}`;
  };

  return (
    <div className="flex items-center justify-center px-8 mb-20">
      <div className="w-full xl:w-8/12 flex items-center justify-between">
        <div
          onClick={setPrevious}
          className="flex items-center pt-3 text-gray-600 hover:text-indigo-700 cursor-pointer"
        >
          <svg
            width="14"
            height="8"
            viewBox="0 0 14 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.1665 4H12.8332"
              stroke="currentColor"
              strokeWidth="1.25"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M1.1665 4L4.49984 7.33333"
              stroke="currentColor"
              strokeWidth="1.25"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M1.1665 4.00002L4.49984 0.666687"
              stroke="currentColor"
              strokeWidth="1.25"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <p className="text-sm ml-3 font-medium leading-none ">Previous</p>
        </div>
        <div className="sm:flex hidden">
          {totalPage.map((number) => {
            return (
              <p
                key={number}
                className={`text-sm font-medium leading-none cursor-pointer text-gray-600 hover:text-indigo-700 ${
                  number === offset
                    ? "text-indigo-900 border-indigo-400"
                    : "border-gray-50"
                } border-t-2 hover:border-indigo-400 pt-3 mr-4 px-2`}
                onClick={() => onPageClick(number)}
              >
                {number}
              </p>
            );
          })}
        </div>
        <div
          onClick={setNext}
          className="flex items-center pt-3 text-gray-600 hover:text-indigo-700 cursor-pointer"
        >
          <p className="text-sm font-medium leading-none mr-3">Next</p>
          <svg
            width="14"
            height="8"
            viewBox="0 0 14 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.1665 4H12.8332"
              stroke="currentColor"
              strokeWidth="1.25"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M9.5 7.33333L12.8333 4"
              stroke="currentColor"
              strokeWidth="1.25"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M9.5 0.666687L12.8333 4.00002"
              stroke="currentColor"
              strokeWidth="1.25"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      </div>
    </div>
  );
};

export const Navbar = () => {
  return (
    <nav className="flex justify-between bg-white text-white w-screen">
      <div className="px-5 xl:px-12 py-3 flex w-full items-center">
        <a className="text-3xl font-bold font-heading" href="/">
          <img
            className="h-9"
            src="https://puregpl.com/wp-content/uploads/2024/02/logo.webp"
            alt="puregpl.com"
          />
        </a>
      </div>
    </nav>
  );
};

export const Footer = () => {
  return (
    <nav className=" bg-gray-900 text-white w-screen">
      <div className="px-5 xl:px-12 py-3 flex w-full justify-center">
        <p>
          Copyright © 2018-{new Date().getFullYear()}{" "}
          <a href="https://puregpl.com">PureGPL.Com</a>
        </p>
      </div>
    </nav>
  );
};

export const Loader = () => {
  return (
    <div className="flex items-center justify-center h-[calc(100vh-328px)]">
      <div className="animate-spin rounded-full border-t-4 border-blue-500 border-opacity-25 border-solid h-12 w-12"></div>
    </div>
  );
};
