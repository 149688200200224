import { useEffect, useState } from "react";
import "./App.css";

import {
  Table,
  // Search,
  // Pagination,
  Navbar,
  Footer,
  Loader,
  OnlyRecord,
} from "./components";

// const findPageNumber = (pathName) => {
//   let result = "1";
//   if (/\d/.test(pathName) && pathName.includes("page")) {
//     result = pathName.match(/\d+/)[0];
//   }

//   return parseInt(result) ? parseInt(result) : 1;
// };

function App() {
  const [data, setData] = useState([]);
  // const [limit, setLimit] = useState(50);
  // const [offset, setOffset] = useState(1);
  // const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(true);

  const fetchChangeLog = (query) =>
    fetch(`https://apis.puregpl.com/api/v1/script/changelog${query}`)
      .then((data) => data.json())
      .then(({ data }) => {
        console.log(data);
        setData(data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });

  useEffect(() => {
    fetchChangeLog(window.location.search);
  }, []);

  // useEffect(() => {
  //   const page = findPageNumber(window.location.pathname);
  //   const searchValue = new URLSearchParams(window.location.search).get(
  //     "search"
  //   );
  //   fetchChangeLog(page, searchValue);
  //   setSearch(searchValue);
  // }, []);

  // const totalPage = Array.from(
  //   { length: Math.round(data.count / limit) },
  //   (_, index) => index + 1
  // );

  return (
    <div className="App">
      <Navbar />
      <div className="bg-[#11B98F]">
        <h1 className="py-10 text-white font-bold text-5xl">ChangeLog</h1>
      </div>
      <div className="pb-16">
        {/* <Search search={search} /> */}
        {loading && <Loader />}
        {!loading && data.length
          ? data.map(({ name, rows }) => {
              return <OnlyRecord name={name} data={rows} />;
            })
          : ""}
        {/* {!loading && <Pagination totalPage={totalPage} offset={offset} />} */}
        {/* {!loading && <Pagination totalPage={totalPage} offset={offset} />} */}
      </div>

      <Footer />
    </div>
  );
}

export default App;
